import React from 'react'
import MoveYourFamily from '../../Home/MoveYourFamily/MoveYourFamily'
import BlogDescription from './BlogDescription/BlogDescription'
import Subscribe from './Subscribe/Subscrbe'
// import MoveYourFamily from '../../Home/MoveYourFamily/MoveYourFamily';
const BlogPosts = () => {
  return <>
    <div className="bg-[#fff]">
      <BlogDescription />
      <Subscribe />
      <MoveYourFamily />
    </div>
  </>
}

export default BlogPosts
