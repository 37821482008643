import { Link } from 'gatsby'
import React from 'react'
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import Img from "gatsby-image"
import blogimg from '../../../../images/blog_inn.png';

const BlogCardData = [
  {
    img: blogimg,
    title: 'Optional Category',
    descrip: 'Super Awesome Blog Title Goes Here Lorem Ipsum Dolor Sit Amet',
  },

  {
    img: blogimg,
    title: 'Optional Category',
    descrip: 'Super Awesome Blog Title Goes Here Lorem Ipsum Dolor Sit Amet',
  },
  {
    img: blogimg,
    title: 'Optional Category',
    descrip: 'Super Awesome Blog Title Goes Here Lorem Ipsum Dolor Sit Amet',
  },
]

const Subscribe = () => {
//   const data = useStaticQuery(graphql`
//   query {
    
//     placeholderImage: file(relativePath: { eq: "blog_inn.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 300) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `)
  return (
    <>
      <div className="">
        <div className="container">
          <div className="max-w-[800px] w-full border border-[#00000047] mx-[auto] pt-[72px] pb-[80px] px-[40px] text-center sm:px-[30px] sm:py-[40px] xs:py-[20px] xs:px-[20px]">
            <h4 className="mb-[24px] text-[#1A1A1A] text-d-6lg font-IbarraRealNova font-[700]">
              Subscribe Heading
            </h4>
            <p className="mb-[24px] text-d-3lg text-[#666666] font-[500] font-Quicksand">
              15-20 words lorem ipsum dolor sit amet, consectetur adipiscing{' '}
              <br className='sm:hidden'/>
              elit ut aliquam, purus sit amet luctus venenatis
            </p>
            <div className="flex justify-center xs:flex-col xs:gap-[10px]">
              <input
                type="text"
                placeholder="Enter your email address"
                className="outline-none max-w-[352px] w-full text-d-3lg font-Quicksand border border-[#00000047] p-[12px] sm:max-w-[250px] xs:max-w-[100%]"
              />
              <Link
                className="bg-[#006BCE] hover:bg-[#0055a5] text-[#fff] py-[14px] px-[19px] text-d-3lg font-Quicksand"
                to=""
              >
                Subscribe
              </Link>
            </div>
          </div>

          <div className="py-[120px] flex flex gap-[40px] md:flex-row md:py-[80px] 2xs_extar:flex-col 2xs_extar:py-[40px]">
            {BlogCardData.map((value) => {
             
              return (
                <>
                  <div className="max-w-[373px] w-full md:max-w-[100%] md:w-full">
                    <img
                      src={value.img}
                      alt="blogimg"
                      className="mb-[24px] md:w-full"
                    />
                              
                              {/* <div className="mb-[24px] md:w-full">
                              <Img fluid={data.placeholderImage.childImageSharp.fluid} />
                                </div> */}
                    <p className="text-d-2lg mb-[8px] text-[#B2B2B2] font-Quicksand uppercase font-[700]">
                      {value.title}
                    </p>
                    <p className="text-sm-5lg font-Quicksand font-[600] sm:text-d-4lg">
                      {value.descrip}
                    </p>
                  </div>
                </>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default Subscribe
