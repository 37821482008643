import { Link } from 'gatsby'
import React from 'react'
import { StaticImage } from "gatsby-plugin-image";

const BlogDescription = () => {
  return (
    <>
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#1A1A1A] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              Super Awesome Blog Title Goes Here Lorem Ipsum Dolor Sit Amet
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <StaticImage src='../../../../images/user_pro.png' alt="profile" />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By The Selby Team
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  August 1, 2022
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <Link to="">
                <StaticImage src='../../../../images/fb_primary.svg' alt="fbicon" />
              </Link>
              <Link to="">
                <StaticImage src='../../../../images/linkedin_primary.svg' alt="linkedinicon" />
              </Link>
              <Link to="">
                <StaticImage src='../../../../images/twitter_primary.svg' alt="twittericon" />
              </Link>
              <Link to="">
                <StaticImage src='../../../../images/Link_primary.svg' alt="linkprimaryicon" />
              </Link>
            </div>
          </div>

          <div className="">
            <StaticImage src='../../../../images/blog_post_main.png' alt="blogpost" />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[600] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Phasellus eu, elit vel nisl nam. Risus aliquam varius pretium,
                placerat mattis lorem suscipit dignissim. Lectus cras feugiat
                orci pulvinar cursus molestie varius. Tortor, elit porttitor sit
                ut quisque ut quis posuere consequat. Sed porttitor feugiat at
                mi interdum cras enim. Velit pellentesque eu in convallis arcu
                purus, integer. Cras eu, varius at hac. Placerat enim, ornare a
                eu enim et. A sed lacus leo tortor convallis massa. Amet ac at
                volutpat consequat integer id vel ullamcorper. Nunc etiam
                faucibus risus in erat. Facilisi nulla at faucibus diam. Dolor,
                quisque eu pellentesque ultricies. Mauris duis diam tempus urna
                nulla.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[600] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Nisl aliquam convallis vitae est facilisis quisque. Nam
                elementum tempus eleifend tincidunt varius. Adipiscing ultrices
                convallis hendrerit duis in facilisis. Ultrices condimentum in
                arcu integer euismod. In nec ultricies vel lorem non sed augue
                integer purus. Habitasse mauris, feugiat condimentum et dictum
                consectetur tempor. Ac mi aliquam phasellus sapien, ac,
                pellentesque cum. Cursus gravida viverra nulla elementum non.
                Vestibulum convallis lectus vulputate facilisi urna. Senectus
                non, mauris hac condimentum mauris pellentesque eget non, sem.
                Velit sed phasellus venenatis, etiam viverra lobortis non.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[600] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Est quam enim suscipit nunc amet dolor quis. Gravida mi sed eu,
                vitae at. Metus sit aliquam varius massa, erat erat integer et
                tincidunt. Risus rhoncus turpis leo neque. Orci eu massa
                porttitor cras consequat a pellentesque. Vulputate cursus sapien
                nulla non egestas rhoncus, sit morbi. Praesent duis mauris risus
                justo sed mauris donec ullamcorper. Nunc hendrerit dapibus
                tincidunt aliquam sem elementum, in.
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <Link to="">
                <StaticImage src='../../../../images/fb_primary.svg' alt="fbicon" />
                </Link>
                <Link to="">
                <StaticImage src='../../../../images/linkedin_primary.svg' alt="linkedinicon" />
                </Link>
                <Link to="">
                <StaticImage src='../../../../images/twitter_primary.svg' alt="twittericon" />
                </Link>
                <Link to="">
                <StaticImage src='../../../../images/Link_primary.svg' alt="linkicon" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BlogDescription
