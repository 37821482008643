import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from '../component/Blog/BlogPost/index';
const BlogPost = () => {
  return (
      <LayoutWrapper
      title="Selby Blog post for Real Estate Success | The Selby Team"
      desc="Give your home a refresh and proper staging to maximize its value with Compass Concierge real estate marketing services. Selby Secrets to Real Estate Success."
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <BlogsPosts />
    </LayoutWrapper>
  );
};

export default BlogPost;
